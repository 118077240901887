import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import { Swipeable } from "react-swipeable"


class WorldTemplate extends React.Component {

  goWorld = (world) => {
    console.log(`go world.`)
    if (!world) {
      return
    }
    window.location.href = world.fields.slug
  }

  render() {
    const post = this.props.data.markdownRemark
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    const lang = post.fields.slug.split('/')[1]
    const worldName = post.fields.slug.split('/')[3]
    const siteTitle = translate('site.name', lang)
    const { notations, altWorld, worldIndex, prevWorld, nextWorld, worlds } = this.props.pageContext
    const headerSubTitle = translate('worlds.index', lang)
    const images = [`worlds/${worldName}_top.jpg`]

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} isReverse={true} world={true}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          images={images}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className="sub-content overview world"
            style={
              {
                backgroundImage: `url(${imageRoot}/worlds/${worldName}_top.jpg)`,
                backgroundSize: `cover`,
                backgroundPosition: `bottom right`
              }
            }
          >
            <h1>{post.frontmatter.title}</h1>
            <hr></hr>
            <h2>{altWorld.frontmatter.title}</h2>
            <Swipeable className="control"
              onSwipedLeft={() => this.goWorld(prevWorld)}
              onSwipedRight={() => this.goWorld(nextWorld)}
              >
              {!prevWorld && (
                <div>
                  <span className="chevron-left disabled"></span>
                  <span className="chevron-left disabled"></span>
                </div>
                )}
              {prevWorld && (
                <Link to={prevWorld.fields.slug} rel="prev"
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}>
                  <span className="chevron-left"></span>
                  <span className="chevron-left"></span>
                </Link>
              )}
              <div className="indicators">
                {
                  worlds.map(
                    (w, i) => {
                      let indicatorClass = ''
                      if (i < worldIndex) {
                        indicatorClass = 'past'
                      } else if (i === worldIndex) {
                        indicatorClass = 'current'
                      }
                      return <Link key={i} to={w.fields.slug} rel="world"
                        style={{
                          boxShadow: `none`,
                          textDecoration: `none`,
                          color: `inherit`,
                        }} className={`indicator ${indicatorClass}`}>
                        </Link>
                    }
                  )
                }
              </div>
              {!nextWorld && (
                <div>
                  <span className="chevron-right disabled"></span>
                  <span className="chevron-right disabled"></span>
                </div>
                )}
              {nextWorld && (
                <Link to={nextWorld.fields.slug} rel="next"
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}>
                  <span className="chevron-right"></span>
                  <span className="chevron-right"></span>
                </Link>
              )}
            </Swipeable>
            {/* {
              post.frontmatter.videos.map(
                (v, i) => {
                  return <div key={i} className="video">
                    <video controls controlsList="nodownload"
                      poster={`${videoRoot}/${v.substring(0, v.length - 4)}.jpg`}>
                      <source src={`${videoRoot}/${v}`} type="video/mp4"></source>
                    </video>
                  </div>
                }
              )
            } */}
            <div className="footer">
                <Link to={`${post.fields.slug}related-movies/`} rel="next"
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}>
                    <button>{translate('world.related-movies', lang)}</button>
                  </Link>
            </div>
          </div>
          <div className="sub-content detail">
            <div className="summary">
              <section>
                <div>
                  {
                    notations.map(
                      (n, i) => {
                        return <p key={i}>
                          <Link to={n.fields.slug}
                          style={{
                            boxShadow: `none`,
                            textDecoration: `none`,
                            color: `inherit`,}}
                          >{n.frontmatter.title}</Link>
                        </p>
                      }
                    )
                  }
                </div>
              </section>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default WorldTemplate

export const pageQuery = graphql`
  query WorldBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        videoRoot
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        videos
      }
    }
  }
`
